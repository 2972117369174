var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              true
                ? _c(
                    "CCard",
                    [
                      _c(
                        "CCardHeader",
                        [
                          _vm._v(" Radio switches "),
                          _c(
                            "CBadge",
                            {
                              staticClass: "mr-auto",
                              attrs: { color: _vm.radio }
                            },
                            [_vm._v(_vm._s(_vm.radio))]
                          ),
                          _c("div", { staticClass: "card-header-actions" }, [
                            _c(
                              "a",
                              {
                                staticClass: "card-header-action",
                                attrs: {
                                  href:
                                    "https://coreui.io/vue/docs/components/switch",
                                  rel: "noreferrer noopener",
                                  target: "_blank"
                                }
                              },
                              [
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v("docs")
                                ])
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "CCardBody",
                        _vm._l(_vm.colors, function(color, key) {
                          return _c(
                            "CSwitch",
                            _vm._b(
                              {
                                key: "radio" + key,
                                staticClass: "mx-1",
                                attrs: {
                                  color: color,
                                  variant: "3d",
                                  type: "radio",
                                  name: "radio",
                                  checked: key === 2,
                                  value: color
                                },
                                on: {
                                  "update:checked": function(val) {
                                    return val ? (_vm.radio = color) : null
                                  }
                                }
                              },
                              "CSwitch",
                              _vm.labelIcon,
                              false
                            )
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _vm._v(" Switch default "),
                      _c("CBadge", { attrs: { color: "primary" } }, [
                        _vm._v(_vm._s(_vm.checker))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "primary",
                          name: "switch1",
                          checked: _vm.checker
                        },
                        on: {
                          "update:checked": function($event) {
                            _vm.checker = $event
                          }
                        }
                      }),
                      _vm._l(
                        [
                          "secondary",
                          "success",
                          "warning",
                          "info",
                          "danger",
                          "light",
                          "dark"
                        ],
                        function(color, key) {
                          return _c("CSwitch", {
                            key: key,
                            staticClass: "mx-1",
                            attrs: { color: color, checked: "" }
                          })
                        }
                      ),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "primary", disabled: "" }
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [_vm._v(" Switch pills ")]),
                  _c(
                    "CCardBody",
                    [
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "primary", checked: "", shape: "pill" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "secondary",
                          checked: "",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "success", checked: "", shape: "pill" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "warning", checked: "", shape: "pill" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "info", checked: "", shape: "pill" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "danger", checked: "", shape: "pill" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "light", checked: "", shape: "pill" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "dark", checked: "", shape: "pill" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "primary", disabled: "", shape: "pill" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [_vm._v(" 3d Switch ")]),
                  _c(
                    "CCardBody",
                    [
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "primary", checked: "", variant: "3d" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "secondary",
                          checked: "",
                          variant: "3d"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "success", checked: "", variant: "3d" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "warning", checked: "", variant: "3d" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "info", checked: "", variant: "3d" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "danger", checked: "", variant: "3d" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "light", checked: "", variant: "3d" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "dark", checked: "", variant: "3d" }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "primary", disabled: "", variant: "3d" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" 3d Switch "),
                    _c("small", [_c("code", [_vm._v("disabled")])])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "primary",
                          checked: "",
                          variant: "3d",
                          disabled: ""
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "secondary",
                          checked: "",
                          variant: "3d",
                          disabled: ""
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "success",
                          checked: "",
                          variant: "3d",
                          disabled: ""
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "warning",
                          checked: "",
                          variant: "3d",
                          disabled: ""
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "info",
                          checked: "",
                          variant: "3d",
                          disabled: ""
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "danger",
                          checked: "",
                          variant: "3d",
                          disabled: ""
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "light",
                          checked: "",
                          variant: "3d",
                          disabled: ""
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "dark",
                          checked: "",
                          variant: "3d",
                          disabled: ""
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: { color: "primary", disabled: "", variant: "3d" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" 3d Switch "),
                    _c("small", [_c("code", [_vm._v("label")])])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "primary",
                              variant: "3d",
                              shape: "square",
                              checked: ""
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "secondary",
                              checked: "",
                              variant: "3d"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "success",
                              checked: "",
                              variant: "3d"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "warning",
                              checked: "",
                              variant: "3d"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: { color: "info", checked: "", variant: "3d" }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "danger",
                              checked: "",
                              variant: "3d"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "light",
                              checked: "",
                              variant: "3d"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: { color: "dark", checked: "", variant: "3d" }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "primary",
                              disabled: "",
                              variant: "3d"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" Switch "),
                    _c("small", [_c("code", [_vm._v('variant="outline"')])])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "primary",
                          checked: "",
                          variant: "outline"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "secondary",
                          checked: "",
                          variant: "outline"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "success",
                          checked: "",
                          variant: "outline"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "warning",
                          checked: "",
                          variant: "outline"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "info",
                          checked: "",
                          variant: "outline"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "danger",
                          checked: "",
                          variant: "outline"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "light",
                          checked: "",
                          variant: "outline"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "dark",
                          checked: "",
                          variant: "outline"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "primary",
                          variant: "outline",
                          disabled: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" Switch "),
                    _c("small", [
                      _c("code", [_vm._v('variant="outline" shape="pill"')])
                    ])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "primary",
                          checked: "",
                          variant: "outline",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "secondary",
                          checked: "",
                          variant: "outline",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "success",
                          checked: "",
                          variant: "outline",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "warning",
                          checked: "",
                          variant: "outline",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "info",
                          checked: "",
                          variant: "outline",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "danger",
                          checked: "",
                          variant: "outline",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "light",
                          checked: "",
                          variant: "outline",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "dark",
                          checked: "",
                          variant: "outline",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "primary",
                          variant: "outline",
                          shape: "pill",
                          disabled: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" Switch "),
                    _c("small", [_c("code", [_vm._v('variant="opposite"')])])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "primary",
                          checked: "",
                          variant: "opposite"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "secondary",
                          checked: "",
                          variant: "opposite"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "success",
                          checked: "",
                          variant: "opposite"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "warning",
                          checked: "",
                          variant: "opposite"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "info",
                          checked: "",
                          variant: "opposite"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "danger",
                          checked: "",
                          variant: "opposite"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "light",
                          checked: "",
                          variant: "opposite"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "dark",
                          checked: "",
                          variant: "opposite"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "primary",
                          variant: "opposite",
                          disabled: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" Switch "),
                    _c("small", [
                      _c("code", [_vm._v('variant="opposite" shape="pill"')])
                    ])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "primary",
                          checked: "",
                          variant: "opposite",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "secondary",
                          checked: "",
                          variant: "opposite",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "success",
                          checked: "",
                          variant: "opposite",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "warning",
                          checked: "",
                          variant: "opposite",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "info",
                          checked: "",
                          variant: "opposite",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "danger",
                          checked: "",
                          variant: "opposite",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "light",
                          checked: "",
                          variant: "opposite",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "dark",
                          checked: "",
                          variant: "opposite",
                          shape: "pill"
                        }
                      }),
                      _c("CSwitch", {
                        staticClass: "mx-1",
                        attrs: {
                          color: "primary",
                          variant: "opposite",
                          shape: "pill",
                          disabled: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" Switch "),
                    _c("small", [_c("code", [_vm._v("label")])])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: { color: "primary", checked: "" }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: { color: "secondary", checked: "" }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: { color: "success", checked: "" }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: { color: "warning", checked: "" }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: { color: "info", checked: "" }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: { color: "danger", checked: "" }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: { color: "light", checked: "" }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: { color: "dark", checked: "" }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: { color: "primary", disabled: "" }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v("shape ")
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" Switch "),
                    _c("small", [_c("code", [_vm._v('label shape="pill"')])])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "primary",
                              checked: "",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "secondary",
                              checked: "",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "success",
                              checked: "",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "warning",
                              checked: "",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: { color: "info", checked: "", shape: "pill" }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "danger",
                              checked: "",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "light",
                              checked: "",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: { color: "dark", checked: "", shape: "pill" }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "primary",
                              shape: "pill",
                              disabled: ""
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" Switch "),
                    _c("small", [
                      _c("code", [_vm._v('label variant="outline"')])
                    ])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "primary",
                              checked: "",
                              variant: "outline"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "secondary",
                              checked: "",
                              variant: "outline"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "success",
                              checked: "",
                              variant: "outline"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "warning",
                              checked: "",
                              variant: "outline"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "info",
                              checked: "",
                              variant: "outline"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "danger",
                              checked: "",
                              variant: "outline"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "light",
                              checked: "",
                              variant: "outline"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "dark",
                              checked: "",
                              variant: "outline"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "primary",
                              variant: "outline",
                              disabled: ""
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" Switch "),
                    _c("small", [
                      _c("code", [_vm._v('label variant="outline"')])
                    ])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "primary",
                              checked: "",
                              variant: "outline",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "secondary",
                              checked: "",
                              variant: "outline",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "success",
                              checked: "",
                              variant: "outline",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "warning",
                              checked: "",
                              variant: "outline",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "info",
                              checked: "",
                              variant: "outline",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "danger",
                              checked: "",
                              variant: "outline",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "light",
                              checked: "",
                              variant: "outline",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "dark",
                              checked: "",
                              variant: "outline",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "primary",
                              variant: "outline",
                              shape: "pill",
                              disabled: ""
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" Switch "),
                    _c("small", [
                      _c("code", [_vm._v('label variant="opposite"')])
                    ])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "primary",
                              checked: "",
                              variant: "opposite"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "secondary",
                              checked: "",
                              variant: "opposite"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "success",
                              checked: "",
                              variant: "opposite"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "warning",
                              checked: "",
                              variant: "opposite"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "info",
                              checked: "",
                              variant: "opposite"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "danger",
                              checked: "",
                              variant: "opposite"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "light",
                              checked: "",
                              variant: "opposite"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "dark",
                              checked: "",
                              variant: "opposite"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "primary",
                              variant: "opposite",
                              disabled: ""
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" Switch "),
                    _c("small", [
                      _c("code", [_vm._v('label variant="opposite"')])
                    ])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "primary",
                              checked: "",
                              variant: "opposite",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelTxt,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "secondary",
                              checked: "",
                              variant: "opposite",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "success",
                              checked: "",
                              variant: "opposite",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "warning",
                              checked: "",
                              variant: "opposite",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "info",
                              checked: "",
                              variant: "opposite",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "danger",
                              checked: "",
                              variant: "opposite",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "light",
                              checked: "",
                              variant: "opposite",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "dark",
                              checked: "",
                              variant: "opposite",
                              shape: "pill"
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      ),
                      _c(
                        "CSwitch",
                        _vm._b(
                          {
                            staticClass: "mx-1",
                            attrs: {
                              color: "primary",
                              variant: "opposite",
                              shape: "pill",
                              disabled: ""
                            }
                          },
                          "CSwitch",
                          _vm.labelIcon,
                          false
                        )
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "12" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [_vm._v(" Sizes ")]),
                  _c(
                    "CCardBody",
                    { staticClass: "p-0" },
                    [
                      _c("CDataTable", {
                        staticClass: "table-align-middle mb-0",
                        attrs: {
                          hover: "",
                          striped: "",
                          items: _vm.items,
                          fields: _vm.fields,
                          "no-sorting": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "example",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "td",
                                  [
                                    _c("CSwitch", {
                                      attrs: {
                                        variant: item.example.variant,
                                        color: item.example.color,
                                        size: item.example.size,
                                        checked: item.example.checked
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "size_prop",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("td", [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(item.size_prop)
                                    }
                                  })
                                ])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }